import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { config } from '../../config'
import { store } from '../apps/store';
import { Events, EventsActions } from '../common/Events';
import { $, $$ } from '../common/helpers';
import { HttpApi } from '../common/https';

(() => {
  //  types

  interface SearchAddressResult {
    id: number;
    street: string;
    details?: string;
    houseNumber?: string;
    postalCode: string;
    city: string;
    lon: string;
    lat: string;
    entranceLon?: string;
    entranceLat?: string;
  }

  interface SearchAddress {
    count: number;
    results: SearchAddressResult[];
  }

  let results: SearchAddressResult[] = [];
  let selectedIndex = -1;
  let searchQuery: string = '';
  let isLoading = false;
  let isSubmitting = false;
  let displayingResultsAs: 'user_addresses' | 'search_results' = 'user_addresses'

  //  templates

  const itemTemplate = (address: string, description: string, selected: boolean, index: number, options: { hasCoordinates: boolean }) => `
<div class="search-item cursor-pointer ${selected ? 'highlighted' : ''}"" data-index="${index}">
  <div class="search-item__data">
    <div class="search-item__mapicon">
    ${
      isSubmitting && selected
        ? '<i class="fad fa-spinner fa-spin" aria-hidden="true"></i>'
        : '<i class="fad fa-map-marker" aria-hidden="true"></i>'
    }
    </div>
    <div class="search-item__address">
      <h5>${address}</h5>
      <h6>${description}</h6>
    </div>  
  </div>
  <div class="search-item__actions">
    ${
      displayingResultsAs === 'user_addresses' && !options.hasCoordinates
        ? '<a href="/szczegoly-uzytkownika" class="action-link">ustaw pozycję na mapie</a>'
        : ''
    }
  </div>
</div>
`;

  //  init

  const inputElement = $('.search-input');
  if (!inputElement) return;
  const inputSpinnerElement = $('.search-spinner');
  const values$ = fromEvent(inputElement, 'input');
  const focus$ = fromEvent(inputElement, 'focus');

  values$
    .pipe(
      distinctUntilChanged(),
      map(() => (inputElement as HTMLInputElement).value),
      tap(() => (selectedIndex = -1)), // reset selected value on every input change
      tap(() => (results = [])), // reset displayed values
      tap(() => (searchQuery = '')), // reset search query
      tap(() => (displayingResultsAs = 'search_results')),
      tap(() => renderResults()), // remove displayed values from DOM
      filter((value) => !!value),
      debounceTime(500),
      tap((value) => {
        searchQuery = value;
        toggleSpinnerElement(true);
        isLoading = true;
        displayingResultsAs = 'search_results'
        renderResults();
      }), // display spinner
      switchMap(
        (value) =>
          new Observable((observer) => {
            HttpApi.post('api/v1/searchAddress/', {
              address: value,
            })
              .then((response) => {
                observer.next(response.data);
                observer.complete();
                toggleSpinnerElement(false);
              })
              .catch((error) => {
                // observer.error(error);
                toggleSpinnerElement(false);
              });
          }),
      ),
    )
    .subscribe((data: any) => {
      isLoading = false;
      // type - SearchAddress
      results = data.results;
      displayingResultsAs = 'search_results'
      renderResults();
    });


  focus$
    .pipe(
      filter(() => config().isLoggedIn),
      filter(() => !(inputElement as HTMLInputElement).value),
      switchMap(() =>
        new Observable((observer) => {
          HttpApi.get('api/v1/shop/customer/ContactsData/',)
            .then((response) => {
              observer.next(response.data);
              observer.complete();
              toggleSpinnerElement(false);
            })
            .catch((error) => {
              // observer.error(error);
              toggleSpinnerElement(false);
            });
        }),
      )
    )
    .subscribe((data: any) => {
      isLoading = false;
      // type - SearchAddress
      results = data.orderAddresses.map(item => {
        const res: SearchAddressResult = {
          id: item.id ?? '',
          street: item.street ?? '', 
          houseNumber: item.houseNumber ?? '', 
          postalCode: item.postalCode ?? '',
          details: item.details ?? item.details ?? '', // delete addressData after backend fix
          city: `${item.city ?? ''}`,
          lon: `${item.lon ?? ''}`,
          lat: `${item.lat ?? ''}`,
          entranceLon: `${item.lon ?? ''}`,
          entranceLat: `${item.lat ?? ''}`,
        };
        return res;

      });
      displayingResultsAs = 'user_addresses'
      renderResults();

    })

  const keys$ = fromEvent<KeyboardEvent>(inputElement, 'keydown');
  keys$
    .pipe(
      filter((key) => key.key === 'ArrowDown'),
      // filter(() => results.length > 0),
      tap((event) => {
        event.preventDefault();
        selectNextResult();
        renderResults();
      }),
    )
    .subscribe();
  keys$
    .pipe(
      filter((key) => key.key === 'ArrowUp'),
      // filter(() => results.length > 0),
      tap((event) => {
        event.preventDefault();
        selectPrevResult();
        renderResults();
      }),
    )
    .subscribe();
  keys$
    .pipe(
      filter((key) => key.key === 'Enter'),
      tap((event) => {
        event.preventDefault();
        submitAddress(results[selectedIndex]);
      }),
    )
    .subscribe();

  // results api

  const selectNextResult = () => {
    selectedIndex++;
    if (selectedIndex >= results.length) {
      selectedIndex = 0;
    }
  };
  const selectPrevResult = () => {
    selectedIndex--;
    if (selectedIndex < 0) {
      selectedIndex = results.length - 1;
    }
  };
  const selectAtIndex = (index: number) => {
    selectedIndex = index;
    if (selectedIndex < 0) {
      selectedIndex = results.length - 1;
    }
    if (selectedIndex >= results.length) {
      selectedIndex = 0;
    }
  };
  const renderResults = () => {
    const searchResults = $('.search-results');
    if (!searchResults) return;

    let html = '';

    if (results.length) {
      results.forEach((result, index) => {
        html += itemTemplate(
          `${result.street} ${result.houseNumber ? result.houseNumber : ''} ${result.details ? result.details : ''}`,
          `${result.postalCode}, ${result.city}`,
          selectedIndex === index,
          index,
          {
            hasCoordinates: !!result.lon && !!result.lat,
          }
        );
      });
    } else {
      // loading
      if (searchQuery && isLoading) {
        html += `<p class="pt-4 text-gray-500 flex items-center justify-center"> <i class="text-2xl fa fa-spin fa-spinner"></i> </p>`;
      }
    }

    searchResults.innerHTML = html;
    bindEventsToResult();
  };
  const bindEventsToResult = () => {
    const items = $$('.search-results .search-item');
    const itemActions = $$('.search-results .search-item .action-link');

    items.forEach((item) => {
      item.addEventListener('mouseenter', (ev) => {
        const target = ev.target as HTMLElement;
        const item: HTMLElement | null = target.closest('.search-item');
        if (item && item.dataset && item.dataset.index) {
          item.classList.add('highlighted');
          selectAtIndex(+item.dataset.index);
        }
      });
      item.addEventListener('mouseleave', (ev) => {
        const target = ev.target as HTMLElement;
        const item: HTMLElement | null = target.closest('.search-item');
        if (item && item.dataset && item.dataset.index) {
          item.classList.remove('highlighted');
          selectAtIndex(+item.dataset.index);
        }
      });
    });

    // ignore actions clicks - they're just links
    itemActions.forEach((item) => {
      item.addEventListener('click', (ev) => {
        ev.stopPropagation()
      })
    })
  };
  const submitAddress = (selectedAddress: SearchAddressResult) => {
    // ignore if item doesn't have coordinates
    // case possible only for user addresses
    if (!selectedAddress.lon || !selectedAddress.lat) {
      return;
    }

    isSubmitting = true;
    renderResults();

    HttpApi.post('api/v1/shop/order/deliveryAddress/', {
        address: selectedAddress,
      })
      .then((response) => {
        store.dispatch({
          type   : 'basket/setState',
          payload: response.data,
        });
        setTimeout(() => (window.location.href = `/restauracje/`));
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const toggleSpinnerElement = (state?: boolean) => {
    if (!inputSpinnerElement) return;
    inputSpinnerElement.classList.toggle('visible', state);
  };

  // events

  const searchResultsElement = $('.search-results');
  if (searchResultsElement) {
    searchResultsElement.addEventListener('click', (ev) => {
      const target = ev.target as HTMLElement;
      const item: HTMLElement | null = target.closest('.search-item');
      if (item && item.dataset && item.dataset.index) {
        const selected = results[+item.dataset.index];
        submitAddress(selected);
      }

      ev.preventDefault();
      ev.stopPropagation();
    });
  }

  document.addEventListener('click', (ev) => {
    results = [];
    renderResults();
  });

  // b2b-pros-more links
  $$('.b2b-pros-more').forEach((linkElement) => {
    linkElement.addEventListener('click', (ev) => {
      ev.preventDefault();
      linkElement.classList.toggle('active');
    });
  });
})();

(() => {
  // direct pickup when clicking on checkbox for pickup
  $('#delivery-radio-pickup').addEventListener('click', (ev) => {
    window['ev'].emit(EventsActions.HOME_SHOW_DIRECT_PICKUP_DIALOG);
  });
  $('#delivery-radio-delivery').addEventListener('click', (ev) => {
    window['ev'].emit(EventsActions.HOME_SET_PICKUP_TO_DELIVERY);
  });
  // $('#home_button_address_dialog').addEventListener('click', (ev) => {
  //   window['ev'].emit(EventsActions.HOME_DISPLAY_ADDRESSES_DIALOG);
  // });
})();
